import Swiper from 'swiper';
import { Pagination } from 'swiper/modules';

class BenefitsSlider {
    constructor(el) {
        if (!this.set(el)) return;
        this.init();
    }

    set(el) {
        if (!el) return false;

        this.root = el;
        this.slider = null;

        return true;
    }

    init() {
        this.initSlider();
    }

    initSlider() {
        // console.log(this.root)

        this.slider = new Swiper(this.root, {
            modules: [Pagination],
            slidesPerView: 1,
            pagination: {
                el: '.js-slider-pagination',
                clickable: true,
                bulletClass: 'swiper__pagination-item',
                bulletActiveClass: 'is-active',
            },
        });
    }
}

function initBenefitsSlider() {
    const sections = document.querySelectorAll('[data-benefits-slider]');
    if (!sections) return;

    sections.forEach(item => new BenefitsSlider(item));
}

if (
    document.readyState === 'complete' ||
    (document.readyState !== 'loading' && !document.documentElement.doScroll)
) {
    initBenefitsSlider();
} else {
    document.addEventListener('DOMContentLoaded', initBenefitsSlider);
}
